const DEV = process.env.NODE_ENV === 'development'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

export default {
  init() {},
  finalize() {
    const player = new Plyr('#player', {
      hideControls: false,
    })

    player.on('ready', (event) => {
      player.toggleControls(false)
    })

    player.on('play', (event) => {
      player.toggleControls(true)
      document.getElementById('playerCover').classList.add('opacity-0')
      //console.log('play')
    })

    player.on('pause', (event) => {
      player.toggleControls(false)
    })

    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}
